module.exports = {
  name: "YourDeal",
  iconPaths: {
    favicon32: "yourdeal/images/favicons/favicon-32x32.png",
    favicon16: "yourdeal/images/favicons/favicon-16x16.png",
    appleTouchIcon: "yourdeal/images/favicons/apple-touch-icon-152x152.png",
    maskIcon: "yourdeal/images/favicons/safari-pinned-tab.svg",
    msTileImage: "yourdeal/images/favicons/msapplication-icon-144x144.png",
  },
  themeColor: "#FA9348",
};
