import NavFactory from '@/client/extensions/helpers/navFactory'
export default async ({ asyncOps, store }) => {
 // older code - we did not have navFactory then
  let adminNavFactory;
  let navItems;
  try {
    adminNavFactory = await require("@/client/assets/static/core/nav/adminSideNavFactory.js");
    navItems = await adminNavFactory.default({ asyncOps, store });
  } catch (e) {
    return {};
  }
  /*
  navItems.user.action = {
    type: "routerNavigation",
    data: {
      to: {
        name: "entity-list",
        params: { entityType: "user" },
      },
    },
  };
*/
  const bids = {
    label: "justopp.bid.navTitle",
    icon: "flag",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-bid",
          // params: { entityType: "user" },
        },
      },
    },
  };

  let group1 = {
    dashboard: navItems.dashboard,
    ecommerceTitle: navItems.ecommerceTitle,
    vendor: navItems.vendor,
    brand: navItems.brand,
    product: navItems.product,
    order: navItems.order,
  };

  let group2 = {
    ...navItems,
  };

  delete group2.dashboard;
  delete group2.ecommerceTitle;
  delete group2.vendor;
  delete group2.product;
  delete group2.order;
  group2.entities.children.homepage = {
    icon: "paperclip",
    label: "justopp.homepage.navTitle",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-entity-entityType",
          params: { entityType: "homepage" },
        },
      },
    },
  };

  const vendorQuickCreate = {
    label: "justopp.adminActions.registerVendor.navTitle",
    icon: "plus",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-actions-registerVendor",
          // params: { entityType: "user" },
        },
      },
    },
  };

  let items =  {
    ...group1,
    vendorQuickCreate,
    ...group2,
  };


  let navFactory = new NavFactory(items);

  let trackingItem = {
    label: "tracking.navTitle",
    icon: "flag",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-tracking",
        },
      },
    },
  };
  navFactory.addItemAfter("media", "tracking", trackingItem)

  return items;
};
