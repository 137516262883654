export default ({ vendorId, userProfile, instance }) => {
  const hiddenOwnerIdField = {
    name: "OwnerId",
    type: "hidden",
    disabled: true,
    group: "hidden",
    value: userProfile.id,
  };

  const adminOwnerIdField = {
    name: "OwnerId",
    type: "Select2",
    translateList: false,
    valueKey: "id",
    labelKey: "fullName",
    label: "core.entity.ownerLabel",
    itemComponent: "iconItem",
    asyncListSource: "entity/User",
    asyncListLive: false,
    textFilterAsSaffronListFilter: true,
    asyncDataTextFilterParamName: "fullName",
    multiple: false,
    asyncListData: {
      simplified: true,
    },
    validation: {
      required: {},
    },
    group: "adminSettings",
  };

  const hiddenVendorIdField = {
    name: "VendorId",
    type: "hidden",
    disabled: true,
    group: "hidden",
  };

  const adminVendorIdField = {
    name: "VendorId",
    type: "Select2",
    translateList: false,
    valueKey: "id",
    labelKey: "name",
    label: "ecommerce.product.fields.vendor",
    itemComponent: "iconItem",
    asyncListSource: "entity/Vendor",
    asyncListLive: false,
    textFilterAsSaffronListFilter: true,
    asyncDataTextFilterParamName: "title",
    multiple: false,
    asyncListData: {
      simplified: true,
    },
    validation: {
      required: {},
    },
    group: "adminSettings",
  };

  const vendorIdField = userProfile.isAdmin ? adminVendorIdField : hiddenVendorIdField;
  const ownerIdField = userProfile.isAdmin ? adminOwnerIdField : hiddenOwnerIdField;


  /*
   const fields = {
    name: {
      name: "name",
      type: "text",
      label: "justopp.vendorDeal.fields.name",
      translatable: true,
      isPrimaryField: true,
      validation: {
        required: {},
        string: {},
      },
      filterable: {},
      itemListOptions: {
        show: true,
      },
      transform: {
        public: true,
      },
      group: "base",
    },
    BrandId: {
      name: "BrandId",
      type: "Select2",
      label: "justopp.vendorBrands.brand",
      placeholder: false,
      translateList: false,
      itemComponent: "iconItem",
      asyncListSource: "justopp/brand/",
      asyncListLive: false,
      // textFilterAsSaffronListFilter: true,
      // asyncDataTextFilterParamName: "name",
      multiple: false,
      asyncListData: {
        simplified: true,
        filters: {
          VendorId: String(userProfile.Vendor.id),
        }
      },
      validation: {
        required: {},
      },
      group: "base",
    },
    code: {
      name: "code",
      type: "text",
      label: "justopp.vendorDeal.fields.code",
      group: "base",
      validation: {
        required: {},
        string: {},
      },
    },
    barcode: {
      name: "barcode",
      type: "text",
      label: "justopp.vendorDeal.fields.barcode",
      group: "base",
      validation: {
        required: {},
        string: {},
      },
    },
    price: {
      name: "price",
      type: "price",
      label: "ecommerce.product.fields.price",
      validation: {
        required: {},
      },
      itemListOptions: {
        show: true,
      },
      group: "base",
    },
    currency: {
      name: "currency",
      type: "currency",
      label: "ecommerce.product.fields.currency",
      validation: {
        required: {},
      },
      itemListOptions: {
        show: true,
      },
      group: "base",
    },

    // todo: limit to 3rd level categories
    CategoryId: {
      name: "CategoryId",
      type: "Select2",
      label: "core.entity.categoryLabel",
      placeholder: false,
      translateList: false,
      itemComponent: "iconItem",
      asyncListSource: "justopp/deals/category",
      asyncListLive: false,
      // textFilterAsSaffronListFilter: true,
      // asyncDataTextFilterParamName: "name",
      multiple: false,
      asyncListData: {
        simplified: true,
      },
      validation: {
        required: {},
      },
      group: "base",
    },
    "type-justoppDealminimumQuantity": {
      name: "minimumQuantity",
      type: "number",
      label: "justopp.justoppDealProductType.minimumQuantity",
      validation: {
      //  required: {},
      },
      group: "base",
      modelTarget: "JustoppDealProductType.minimumQuantity",
    },
    "type-justoppDealmaximumQuantity": {
      name: "maximumQuantity",
      type: "number",
      label: "justopp.justoppDealProductType.maximumQuantity",
      validation: {
        required: {},
      },
      group: "base",
      modelTarget: "JustoppDealProductType.maximumQuantity",
    },

    shortIntroText: {
      name: "shortIntroText",
      type: "text",
      label: "justopp.vendorDeal.fields.shortIntroText",
      legend: "justopp.vendorDeal.fields.shortIntroTextHint",
      legendIcon: "eye",
      translatable: true,
      group: "info",
      class: "width-expand",
      style: {'flex': '100%'}
    },
    weightUnits: {
      name: "weightUnits",
      type: "select",
      label: "justopp.product.fields.weightUnits.label",
      list: [
        { value: "kg", label: "justopp.product.fields.weightUnits.kg" },
        { value: "lb", label: "justopp.product.fields.weightUnits.lb" },
      ],
      group: "info",
    },
    weight: {
      name: "weight",
      type: "text",
      label: "justopp.vendorDeal.fields.weight",
      group: "info",
    },
    packageSizeUnits: {
      name: "packageSizeUnits",
      type: "select",
      label: "justopp.product.fields.packageSizeUnits.label",
      list: [
        { value: "cm", label: "justopp.product.fields.packageSizeUnits.cm" },
        { value: "inch", label: "justopp.product.fields.packageSizeUnits.inch" },
      ],
      group: "info",
    },
    packageWidth: {
      name: "packageWidth",
      type: "text",
      label: "justopp.vendorDeal.fields.packageWidth",
      group: "info",
    },
    packageHeight: {
      name: "packageHeight",
      type: "text",
      label: "justopp.vendorDeal.fields.packageHeight",
      group: "info",
    },
    packageDepth: {
      name: "packageDepth",
      type: "text",
      label: "justopp.vendorDeal.fields.packageDepth",
      group: "info",
    },
    warranty: {
      name: "warranty",
      type: "select",
      list: [
        {
          value: 0,
          label: "ecommerce.product.fields.warranty.0",
        },
        {
          value: 1,
          label: "ecommerce.product.fields.warranty.1",
        },
        {
          value: 2,
          label: "ecommerce.product.fields.warranty.2",
        },
        {
          value: 3,
          label: "ecommerce.product.fields.warranty.3",
        },
        {
          value: 4,
          label: "ecommerce.product.fields.warranty.4",
        },
        {
          value: 5,
          label: "ecommerce.product.fields.warranty.5",
        },
      ],
      label: "justopp.vendorDeal.fields.warranty",
      group: "info",
    },
    numAccessories: {
      name: "numAccessories",
      type: "select",
      list: [
        {
          value: 0,
          label: "ecommerce.product.fields.numAccessories.0",
        },
        {
          value: 1,
          label: "ecommerce.product.fields.numAccessories.1",
        },
        {
          value: 2,
          label: "ecommerce.product.fields.numAccessories.2",
        },
        {
          value: 3,
          label: "ecommerce.product.fields.numAccessories.3",
        },
        {
          value: 4,
          label: "ecommerce.product.fields.numAccessories.4",
        },
        {
          value: 5,
          label: "ecommerce.product.fields.numAccessories.5",
        },
        {
          value: 6,
          label: "ecommerce.product.fields.numAccessories.6",
        },
        {
          value: 7,
          label: "ecommerce.product.fields.numAccessories.7",
        },
        {
          value: 8,
          label: "ecommerce.product.fields.numAccessories.8",
        },
        {
          value: 9,
          label: "ecommerce.product.fields.numAccessories.9",
        },
        {
          value: 10,
          label: "ecommerce.product.fields.numAccessories.10",
        },
      ],
      label: "justopp.vendorDeal.fields.numAccessories",
      group: "info",
    },

    accessoriesDescription: {
      name: "accessoriesDescription",
      type: "textarea",
      maxCharacters: 400,
      label: "ecommerce.product.fields.accessoriesDescription.label",
      group: "info",
    },
    color: {
      name: "color",
      type: "text",
      label: "justopp.vendorDeal.fields.color",
      group: "info",
    },
    information: {
      name: "information",
      type: "editor",
      label: "justopp.vendorDeal.fields.information",
      translatable: true,
      validation: {
        required: {},
      },
      group: "info",
    },
    additionalInformation: {
      name: "additionalInformation",
      type: "repeatable",
      label: "justopp.vendorDeal.fields.additionalInformation",
      repeatable: {
        fullWidth: true,
      },
      translatable: true,
      fields: {
        title: {
          name: "title",
          type: "text",
          label: "ecommerce.product.fields.additionalInformation.title",
        },
        content: {
          name: "content",
          type: "editor",
          label: "ecommerce.product.fields.additionalInformation.content",
        },
      },
      group: "info",
    },
    plug: {
      name: "plug",
      type: "text",
      label: "justopp.vendorDeal.fields.plug",
      group: "info",
    },
    watts: {
      name: "watts",
      type: "text",
      label: "justopp.vendorDeal.fields.watts",
      group: "info",
    },
    introImage: {
      name: "introImage",
      type: "quickMedia",
      valueField: "url",
      accept: "image/*",
      imagesOnly: true,
      label: "justopp.vendorDeal.fields.introImage",
      legend: "justopp.vendorDeal.fields.introImageLegend",
      legendIcon: "eye",
      group: "media",
      mediaManagerProps: {
        filters: {
          taxonomy: "vendor-" + vendorId + "-products",
        },
        uploadData: {
          taxonomy: "vendor-" + vendorId + "-products",
        },
      },
      validation: {
        required: {},
      },
    },
    images: {
      name: "images",
      type: "repeatableMedia",
      label: "justopp.vendorDeal.fields.images",
      fields: {
        url: {
          name: "url",
          type: "quickMedia",
          accept: "image/*",
          imagesOnly: true,
          label: "ecommerce.product.fields.images.url",
        },
      },
      mediaFieldKey: "url",
      mediaDataKey: "url",
      maxImageWidth: 1920,
      maxImageHeight: 1080,
      group: "media",
    },
    specFile: {
      name: "specFile",
      type: "quickMedia",
      accept: "application/pdf",
      imagesOnly: false,
      label: "justopp.vendorDeal.fields.specFile",
      legend: "justopp.vendorDeal.fields.specFileLegend",
      legendIcon: "warning-triangle",
      group: "media",
    },
    productFiles: {
      name: "productFiles",
      type: "repeatable",

      label: "justopp.vendorDeal.fields.productFiles",
      fields: {
        fileUrl: {
          name: "fileUrl",
          type: "quickMedia",
          accept: "*",
          imagesOnly: false,
          label: "ecommerce.product.fields.productFiles.fileUrlLabel",
        },
      },
      group: "media",
    },
    videoUrl: {
      name: "videoUrl",
      type: "text",
      label: "justopp.product.fields.videoUrl",
      legend: "justopp.product.fields.videoUrlLegend",
      group: "media",
    },

    youtubeVideoId: {
      name: "youtubeVideoId",
      type: "youtubeVideoId",
      label: "justopp.product.fields.youtubeVideoId",
      legend: "justopp.product.fields.youtubeVideoIdLegend",
      group: "media",
    },

    "type-justoppDealdeliveryMethod": {
      name: "deliveryMethod",
      type: "select",
      label: "justopp.justoppDealProductType.deliveryMethod",
      list: [
        {
          value: "1",
          label: "justopp.justoppDealProductType.deliveryMethod1",
        },
        {
          value: "2",
          label: "justopp.justoppDealProductType.deliveryMethod2",
        },
        {
          value: "3",
          label: "justopp.justoppDealProductType.deliveryMethod3",
        },
        {
          value: "4",
          label: "justopp.justoppDealProductType.deliveryMethod4",
        },
        {
          value: "5",
          label: "justopp.justoppDealProductType.deliveryMethod5",
        },
      ],
      validation: {
        required: {},
      },
      group: "delivery",
      modelTarget: "JustoppDealProductType.deliveryMethod",
    },

    "type-justoppDealWarehouseCountry": {
      name: "warehouseCountry",
      type: "text",
      label: "justopp.justoppDealProductType.warehouseCountry",
      validation: {
        required: {},
      },
      group: "delivery",
      modelTarget: "JustoppDealProductType.warehouseCountry",
    },
    "type-justoppDealWarehouseZipCode": {
      name: "warehouseZipCode",
      type: "text",
      label: "justopp.justoppDealProductType.warehouseZipCode",
      validation: {
        required: {},
      },
      group: "delivery",
      modelTarget: "JustoppDealProductType.warehouseZipCode",
    },

    "type-justoppDealWarehouseAddress": {
      name: "warehouseAddress",
      type: "text",
      label: "justopp.justoppDealProductType.warehouseAddress",
      validation: {
        required: {},
      },
      group: "delivery",
      modelTarget: "JustoppDealProductType.warehouseAddress",
    },
    "type-justoppDealWarehouseContactInfo": {
      name: "WarehouseContactInfo",
      type: "text",
      label: "justopp.justoppDealProductType.warehouseContactInfo",
      validation: {
        required: {},
      },
      group: "delivery",
      modelTarget: "JustoppDealProductType.warehouseContactInfo",
    },

    "type-justoppDealallowedCountries": {
      name: "allowedCountries",
      type: "countryList",
      multiple: true,
      autoTranslate: true,
      translateList: false,
      label: "justopp.vendorDeal.fields.allowedCountries",
      placeholder: "justopp.vendorDeal.fields.allowedCountriesPlaceholder",
      legend: "justopp.vendorDeal.fields.allowedCountriesLegend",
      legendIcon: "question",
      group: "delivery",
      modelTarget: "JustoppDealProductType.allowedCountries",
    },
    "type-justoppDealrestrictedCountries": {
      name: "restrictedCountries",
      type: "countryList",
      multiple: true,
      label: "justopp.vendorDeal.fields.restrictedCountries",
      legend: "justopp.vendorDeal.fields.restrictedCountriesLegend",
      legendIcon: "question",
      group: "delivery",
      modelTarget: "JustoppDealProductType.restrictedCountries",
    },
    "type-auctionstartingPrice": {
      name: "startingPrice",
      type: "price",
      label: "justopp.auctionProductType.startingPrice",
      group: "auction",
      validation: {

      },
      modelTarget: "AuctionProductType.startingPrice",
    },

    //////////////////todo: hide
    typeList: {
      name: "typeList",
      label: "ecommerce.product.fields.productTypes",
      type: "select2",
      multiple: true,
      list: [
        {
          value: "default",
          label: "ecommerce.product.types.default.label",
        },
        {
          value: "test",
          label: "ecommerce.product.types.test.label",
        },
        {
          value: "basicVariant",
          label: "ecommerce.product.types.basicVariant.label",
        },
        {
          value: "justoppDeal",
          label: "justopp.justoppDealProductType.typeLabel",
        },
        {
          value: "auction",
          label: "justopp.auctionProductType.typeLabel",
        },
      ],
      disabled: true,
      group: "hidden",
      style: "display: none;",
    },
    VendorId: vendorIdField,
    OwnerId: ownerIdField,
  };
   */
  const fields = {
    name: {
      name: "name",
      type: "text",
      label: "justopp.vendorDeal.fields.name",
      translatable: true,
      isPrimaryField: true,
      validation: {
        required: {},
        string: {},
      },
      filterable: {},
      itemListOptions: {
        show: true,
      },
      transform: {
        public: true,
      },
      group: "base",
    },
    BrandId: {
      name: "BrandId",
      type: "Select2",
      label: "justopp.vendorBrands.brand",
      placeholder: false,
      translateList: false,
      itemComponent: "iconItem",
      asyncListSource: "justopp/brand/",
      asyncListLive: false,
      // textFilterAsSaffronListFilter: true,
      // asyncDataTextFilterParamName: "name",
      multiple: false,
      asyncListData: {
        simplified: true,
      },
      validation: {
        required: {},
      },
      group: "base",
    },
    code: {
      name: "code",
      type: "text",
      label: "justopp.vendorDeal.fields.code",
      group: "base",
      validation: {
        required: {},
        string: {},
      },
    },
    /*
    barcode: {
      name: "barcode",
      type: "text",
      label: "justopp.vendorDeal.fields.barcode",
      group: "base",
      validation: {
        required: {},
        string: {},
      },
    },
    */
    vendorPrice: {
      name: "vendorPrice",
      type: "price",
      label: "ecommerce.product.fields.price",
      validation: {
        required: {},
      },
      group: "base",
    },
    /*
    price: {
      name: "price",
      type: "price",
      label: "ecommerce.product.fields.price",
      itemListOptions: {
        show: true,
      },
      group: "base",
    },
    */
    currency: {
      name: "currency",
      type: "hidden",
      label: "ecommerce.product.fields.currency",
      validation: {
        required: {},
      },
      itemListOptions: {
        show: true,
      },
      group: "base",
      defaultValue: "ILS",
    },

    CategoryId: {
      name: "CategoryId",
      type: "Select2",
      label: "core.entity.categoryLabel",
      placeholder: false,
      translateList: false,
      itemComponent: "iconItem",
      asyncListSource: "justopp/deals/category",
      asyncListLive: false,
      // textFilterAsSaffronListFilter: true,
      // asyncDataTextFilterParamName: "name",
      multiple: false,
      asyncListData: {
        simplified: true,
      },
      validation: {
        required: {},
      },
      group: "base",
    },
    "type-justoppDealminimumQuantity": {
      name: "minimumQuantity",
      type: "hidden",
      label: "justopp.justoppDealProductType.minimumQuantity",
      validation: {
      //  required: {},
      },
      group: "base",
      defaultValue: 1,
      modelTarget: "JustoppDealProductType.minimumQuantity",
    },
    "type-justoppDealmaximumQuantity": {
      name: "maximumQuantity",
      type: "number",
      label: "justopp.justoppDealProductType.maximumQuantity",
      validation: {
        required: {},
      },
      group: "base",
      modelTarget: "JustoppDealProductType.maximumQuantity",
    },

    shortIntroText: {
      name: "shortIntroText",
      type: "text",
      label: "justopp.vendorDeal.fields.shortIntroText",
      legend: "justopp.vendorDeal.fields.shortIntroTextHint",
      legendIcon: "eye",
      translatable: true,
      group: "info",
      class: "width-expand",
      style: {'flex': '100%'}
    },
    weightUnits: {
      name: "weightUnits",
      type: "select",
      label: "justopp.product.fields.weightUnits.label",
      list: [
        { value: "kg", label: "justopp.product.fields.weightUnits.kg" },
        { value: "lb", label: "justopp.product.fields.weightUnits.lb" },
      ],
      group: "info",
    },
    weight: {
      name: "weight",
      type: "text",
      label: "justopp.vendorDeal.fields.weight",
      group: "info",
    },
    packageSizeUnits: {
      name: "packageSizeUnits",
      type: "select",
      label: "justopp.product.fields.packageSizeUnits.label",
      list: [
        { value: "cm", label: "justopp.product.fields.packageSizeUnits.cm" },
        { value: "inch", label: "justopp.product.fields.packageSizeUnits.inch" },
      ],
      group: "info",
    },
    packageWidth: {
      name: "packageWidth",
      type: "text",
      label: "justopp.vendorDeal.fields.packageWidth",
      group: "info",
    },
    packageHeight: {
      name: "packageHeight",
      type: "text",
      label: "justopp.vendorDeal.fields.packageHeight",
      group: "info",
    },
    packageDepth: {
      name: "packageDepth",
      type: "text",
      label: "justopp.vendorDeal.fields.packageDepth",
      group: "info",
    },
    warranty: {
      name: "warranty",
      type: "select",
      list: [
        {
          value: 0,
          label: "ecommerce.product.fields.warranty.0",
        },
        {
          value: 1,
          label: "ecommerce.product.fields.warranty.1",
        },
        {
          value: 2,
          label: "ecommerce.product.fields.warranty.2",
        },
        {
          value: 3,
          label: "ecommerce.product.fields.warranty.3",
        },
        {
          value: 4,
          label: "ecommerce.product.fields.warranty.4",
        },
        {
          value: 5,
          label: "ecommerce.product.fields.warranty.5",
        },
      ],
      label: "justopp.vendorDeal.fields.warranty",
      group: "info",
    },
    numAccessories: {
      name: "numAccessories",
      type: "select",
      list: [
        {
          value: 0,
          label: "ecommerce.product.fields.numAccessories.0",
        },
        {
          value: 1,
          label: "ecommerce.product.fields.numAccessories.1",
        },
        {
          value: 2,
          label: "ecommerce.product.fields.numAccessories.2",
        },
        {
          value: 3,
          label: "ecommerce.product.fields.numAccessories.3",
        },
        {
          value: 4,
          label: "ecommerce.product.fields.numAccessories.4",
        },
        {
          value: 5,
          label: "ecommerce.product.fields.numAccessories.5",
        },
        {
          value: 6,
          label: "ecommerce.product.fields.numAccessories.6",
        },
        {
          value: 7,
          label: "ecommerce.product.fields.numAccessories.7",
        },
        {
          value: 8,
          label: "ecommerce.product.fields.numAccessories.8",
        },
        {
          value: 9,
          label: "ecommerce.product.fields.numAccessories.9",
        },
        {
          value: 10,
          label: "ecommerce.product.fields.numAccessories.10",
        },
      ],
      label: "justopp.vendorDeal.fields.numAccessories",
      group: "info",
    },

    accessoriesDescription: {
      name: "accessoriesDescription",
      type: "textarea",
      maxCharacters: 400,
      label: "ecommerce.product.fields.accessoriesDescription.label",
      group: "info",
    },
    color: {
      name: "color",
      type: "text",
      label: "justopp.vendorDeal.fields.color",
      group: "info",
    },
    information: {
      name: "information",
      type: "editor",
      label: "justopp.vendorDeal.fields.information",
      translatable: true,
      group: "info",
    },
    additionalInformation: {
      name: "additionalInformation",
      type: "repeatable",
      label: "justopp.vendorDeal.fields.additionalInformation",
      repeatable: {
        fullWidth: true,
      },
      translatable: true,
      fields: {
        title: {
          name: "title",
          type: "text",
          label: "ecommerce.product.fields.additionalInformation.title",
        },
        content: {
          name: "content",
          type: "editor",
          label: "ecommerce.product.fields.additionalInformation.content",
        },
      },
      group: "info",
    },
    /*
    plug: {
      name: "plug",
      type: "text",
      label: "justopp.vendorDeal.fields.plug",
      group: "info",
    },
    watts: {
      name: "watts",
      type: "text",
      label: "justopp.vendorDeal.fields.watts",
      group: "info",
    },
    */

    introImage: {
      name: "introImage",
      type: "quickMedia",
      valueField: "url",
      accept: "image/*",
      imagesOnly: true,
      label: "justopp.vendorDeal.fields.introImage",
      legend: "justopp.vendorDeal.fields.introImageLegend",
      legendIcon: "eye",
      group: "media",
      mediaManagerProps: {
        filters: {
          taxonomy: "vendor-" + vendorId + "-products",
        },
        uploadData: {
          taxonomy: "vendor-" + vendorId + "-products",
        },
      },
    },
    images: {
      name: "images",
      type: "repeatableMedia",
      label: "justopp.vendorDeal.fields.images",
      fields: {
        url: {
          name: "url",
          type: "quickMedia",
          accept: "image/*",
          imagesOnly: true,
          label: "ecommerce.product.fields.images.url",
        },
      },
      mediaFieldKey: "url",
      mediaDataKey: "url",
      maxImageWidth: 1920,
      maxImageHeight: 1080,
      group: "media",
    },
    specFile: {
      name: "specFile",
      type: "quickMedia",
      accept: "application/pdf",
      imagesOnly: false,
      label: "justopp.vendorDeal.fields.specFile",
      legend: "justopp.vendorDeal.fields.specFileLegend",
      legendIcon: "warning-triangle",
      group: "media",
    },
    productFiles: {
      name: "productFiles",
      type: "repeatable",

      label: "justopp.vendorDeal.fields.productFiles",
      fields: {
        fileUrl: {
          name: "fileUrl",
          type: "quickMedia",
          accept: "*",
          imagesOnly: false,
          label: "ecommerce.product.fields.productFiles.fileUrlLabel",
        },
      },
      group: "media",
    },
    videoUrl: {
      name: "videoUrl",
      type: "text",
      label: "justopp.product.fields.videoUrl",
      legend: "justopp.product.fields.videoUrlLegend",
      group: "media",
    },
    youtubeVideoId: {
      name: "youtubeVideoId",
      type: "youtubeVideoId",
      label: "justopp.product.fields.youtubeVideoId",
      legend: "justopp.product.fields.youtubeVideoIdLegend",
      group: "media",
    },
/*
    "type-justoppDealdeliveryMethod": {
      name: "deliveryMethod",
      type: "select",
      label: "justopp.justoppDealProductType.deliveryMethod",
      list: [
        {
          value: "1",
          label: "justopp.justoppDealProductType.deliveryMethod1",
        },
        {
          value: "2",
          label: "justopp.justoppDealProductType.deliveryMethod2",
        },
        {
          value: "3",
          label: "justopp.justoppDealProductType.deliveryMethod3",
        },
        {
          value: "4",
          label: "justopp.justoppDealProductType.deliveryMethod4",
        },
        {
          value: "5",
          label: "justopp.justoppDealProductType.deliveryMethod5",
        },
      ],
      validation: {
        required: {},
      },
      group: "delivery",
      modelTarget: "JustoppDealProductType.deliveryMethod",
    },
    "type-justoppDealWarehouseCountry": {
      name: "warehouseCountry",
      type: "text",
      label: "justopp.justoppDealProductType.warehouseCountry",
      validation: {
        required: {},
      },
      group: "delivery",
      modelTarget: "JustoppDealProductType.warehouseCountry",
    },
    "type-justoppDealWarehouseZipCode": {
      name: "warehouseZipCode",
      type: "text",
      label: "justopp.justoppDealProductType.warehouseZipCode",
      validation: {
        required: {},
      },
      group: "delivery",
      modelTarget: "JustoppDealProductType.warehouseZipCode",
    },
    "type-justoppDealWarehouseAddress": {
      name: "warehouseAddress",
      type: "text",
      label: "justopp.justoppDealProductType.warehouseAddress",
      validation: {
        required: {},
      },
      group: "delivery",
      modelTarget: "JustoppDealProductType.warehouseAddress",
    },
    "type-justoppDealWarehouseContactInfo": {
      name: "WarehouseContactInfo",
      type: "text",
      label: "justopp.justoppDealProductType.warehouseContactInfo",
      validation: {
        required: {},
      },
      group: "delivery",
      modelTarget: "JustoppDealProductType.warehouseContactInfo",
    },
    "type-justoppDealallowedCountries": {
      name: "allowedCountries",
      type: "countryList",
      multiple: true,
      autoTranslate: true,
      translateList: false,
      label: "justopp.vendorDeal.fields.allowedCountries",
      placeholder: "justopp.vendorDeal.fields.allowedCountriesPlaceholder",
      legend: "justopp.vendorDeal.fields.allowedCountriesLegend",
      legendIcon: "question",
      group: "delivery",
      modelTarget: "JustoppDealProductType.allowedCountries",
    },
    "type-justoppDealrestrictedCountries": {
      name: "restrictedCountries",
      type: "countryList",
      multiple: true,
      label: "justopp.vendorDeal.fields.restrictedCountries",
      legend: "justopp.vendorDeal.fields.restrictedCountriesLegend",
      legendIcon: "question",
      group: "delivery",
      modelTarget: "JustoppDealProductType.restrictedCountries",
    },
    "type-auctionstartingPrice": {
      name: "startingPrice",
      type: "price",
      label: "justopp.auctionProductType.startingPrice",
      group: "auction",
      validation: {

      },
      modelTarget: "AuctionProductType.startingPrice",
    },
*/
    //////////////////todo: hide
    typeList: {
      name: "typeList",
      label: "ecommerce.product.fields.productTypes",
      type: "select2",
      multiple: true,
      list: [
        {
          value: "default",
          label: "ecommerce.product.types.default.label",
        },
        {
          value: "test",
          label: "ecommerce.product.types.test.label",
        },
        {
          value: "basicVariant",
          label: "ecommerce.product.types.basicVariant.label",
        },
        {
          value: "justoppDeal",
          label: "justopp.justoppDealProductType.typeLabel",
        },
        {
          value: "auction",
          label: "justopp.auctionProductType.typeLabel",
        },
      ],
      disabled: true,
      group: "hidden",
      style: "display: none;",
    },
    VendorId: vendorIdField,
    OwnerId: ownerIdField,
  };

  if (userProfile?.isAdmin) {
    fields.featured = {
      name: "featured",
      type: "checkbox",
      label: "ecommerce.product.fields.featured",
      group: "adminSettings",
    };
  }

  return {
    fields: fields,
    groups: {
      top: {
        component: "block",
        props: {
          class: "top-block",
        },
      },

      base: {
        component: "CollapsableFieldSet",
        props: {
          title: "justopp.vendorDeal.basicSettingsFieldsetTitle",
          initiallyOpened: true,
          class: "CollapsableFieldSet",
          maxFieldsPerRow: 2,
        },
      },
      adminSettings: {
        component: "CollapsableFieldSet",
        props: {
          title: "justopp.vendorDeal.adminSettingsFieldsetTitle",
          initiallyOpened: false,
          class: "CollapsableFieldSet",
          maxFieldsPerRow: 2,
        },
      },
      info: {
        component: "CollapsableFieldSet",
        props: {
          title: "justopp.vendorDeal.infoFieldsetTitle",
          initiallyOpened: false,
          class: "CollapsableFieldSet",
          maxFieldsPerRow: 2,
        },
      },
      /*
      brand: {
        component: "CollapsableFieldSet",
        props: {
          title: "justopp.vendorDeal.brandFieldsetTitle",
          initiallyOpened: false,
          class: "CollapsableFieldSet",
          maxFieldsPerRow: 2,
        },
      },
      */
      media: {
        component: "CollapsableFieldSet",
        props: {
          title: "justopp.vendorDeal.mediaFieldsetTitle",
          initiallyOpened: false,
          class: "CollapsableFieldSet",
          maxFieldsPerRow: 2,
        },
      },
      /*
      delivery: {
        component: "CollapsableFieldSet",
        props: {
          title: "justopp.vendorDeal.deliveryFieldsetTitle",
          initiallyOpened: false,
          class: "CollapsableFieldSet",
          maxFieldsPerRow: 2,
        },
      },
      auction: {
        component: "CollapsableFieldSet",
        props: {
          title: "justopp.vendorDeal.auctionFieldsetTitle",
          initiallyOpened: false,
          class: "CollapsableFieldSet",
          maxFieldsPerRow: 2,
        },
      },
      */
      /*
      TranslationsGroup: {
        component: "CollapsableFieldSet",
        props: {
          title: "justopp.vendorDeal.translationsFieldsetTitle",
          initiallyOpened: false,
          class: "translations",
          maxFieldsPerRow: 2,
        },
      },

       */
      hidden: {
        component: "block",
        props: {
          style: "display: none",
        },
      },
    },
  };
};
