/**
 * When a route meta has requiresGuest, will redirect logged in users based on store/auth module
 * Will hang and wait, if the auth status is not determined.
 *
 * If route meta has requiresGuestRedirect property - will redirect there
 * Otherwise, will redirect based on app config - config.router.requiresGuestRedirect
 * Finally, if both missing, will redirect to '/' as a last resort.
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
  return {
    routerMethod: "saffronBus",
    handler: async (to, from, context) => {
      console.log("require vendor starts");

      let rejectRedirect = { name: "unauthorised" };

      // wait for user. no more than 2 seconds
      await utilities.waitForUser(router.app.store);

      if (!router.app.store.getters["user/isAuthenticated"]) {
        return { name: "user-login" };
      }

      let profile = router.app.store.getters["user/profile"];
      if (!profile || !profile.Vendor) {
        console.log("rejected", rejectRedirect);
        router.push(rejectRedirect);
        return;
      }

      if (!context.vendorIdFromParam) {
        return true;
      }

      let targetVendorId = parseInt(to.params[context.vendorIdFromParam]);

      if (targetVendorId !== parseInt(profile.Vendor.id)) {
        console.log('rejecting bad id', targetVendorId, profile.Vendor.id)
           return rejectRedirect;
      }
      console.log("to", to);
      console.log("targetVendorId", targetVendorId);
      console.log("profile", router.app.store.getters["user/profile"]);

      return true;
    },
  };
};
