const permissiveRequiredAlpha = {
  $validator: (value) => {
    if (!value && value !== "0" && value !== 0) {
      return true;
    }
    if (typeof value !== "string" || value.length > 200) {
      return false;
    }
    let regex = /^[a-zA-Z\u0590-\u05FF0-9\s\-_"'?!]*$/;
    if (!regex.test(value)) {
      return false;
    }

    const requireCharsRegex = /[a-zA-Z\u0590-\u05FF]/;
    if (!requireCharsRegex.test(value)) {
      return false;
    }

    return true;
  },
  // $message: 'test cool'
};

export default permissiveRequiredAlpha;
