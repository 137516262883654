export default {
  currency: {
    useConversions: true,
    default: "ILS",
    availableCurrencies: ["ILS","EUR", "USD", "GBP"],
  },
  productTypes: {
    activeProductTypes: [
      { value: "justoppDeal", label: "justopp.justoppDealProductType.typeLabel" },
      { value: "auction", label: "justopp.auctionProductType.typeLabel" },
    ],
    defaultProductTypes: ["justoppDeal", "auction"],
  },
  order: {
    statuses: ["new", "canceled", "failed", "completed", "pending", "payed"],
  },
};
